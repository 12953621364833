import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import BackgroundImage from 'gatsby-background-image'
import "./CaseStudies.css";


function CaseStudies(props) {
    const data = props;

    console.log(data)

    return (
        <div className="py-0 md:py-32">
            <div className="m-auto max-w-6xl text-center">
                <div className="flex flex-wrap">
                    {data.cases.map((cs, index) => (
                        <Link to={`${cs.node.uri}`} className=" w-full sm:w-1/2 md:w-1/3 overflow-hidden" key={`case-stury-${index}`}>
                            <BackgroundImage
                                Tag="section"
                                className="casepg-bg-container w-full flex items-center"
                                fluid={cs.node.caseStudyAcf.featuredImage.imageFile.childImageSharp.fluid}
                                backgroundColor={`#040e18`}
                            >
                                <div className="relative csp-el-cont flex items-center">
                                    <div className="csp-fk"></div>
                                    <div className=" py-4 px-8 relative">
                                        <h3 className="text-2xl text-white">
                                            {cs.node.title}
                                        </h3>
                                    </div>
                                </div>
                            </BackgroundImage>

                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CaseStudies;

