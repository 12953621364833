import React from "react"
import { graphql, Link } from "gatsby"


// import Introduction from "../components/Global/Introduction"
import Main from "../components/CaseStudies/Main"
import CaseStudies from "../components/CaseStudies/CaseStudies"
import ContactForm from "../components/Global/ContactForm"
import Breadcrumb from "../components/Global/Breadcrumb"


import Layout from '../components/layout'


function CaseStudiesTemplate(props) {
    // console.log(props)
    let data = props.data.wpgraphql;

    function getBreadcrumb (){
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url:  `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/" }`
        })
        bCrumb.push({
            text: data.page.breadcrumbAcf.breadcrumbName,
        })
        return bCrumb;
    }

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://enimeris.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://enimeris.com${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData = {constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
            >
                <div>
                    {/* <section>
                        <Introduction backImg={data.page.caseStudiesPageAcf.introductionBackgroundImage} title={data.page.caseStudiesPageAcf.introductionHeader} />
                    </section> */}
                    {data.page.breadcrumbAcf.breadcrumbName && (
                        <section>
                            <Breadcrumb list={getBreadcrumb()} />
                        </section>
                    )}
                    <section>
                        <Main backImg={data.page.caseStudiesPageAcf.introductionBackgroundImage} content={data.page.caseStudiesPageAcf.mainContent} title={data.page.caseStudiesPageAcf.introductionHeader} />
                    </section>
                    <section>
                        <CaseStudies cases={data.caseStudies.edges} />
                    </section>
                    <section>
                        <ContactForm 
                            form={data.template.contactInformationTemplate.form} 
                            responses={data.template.contactInformationTemplate.responseMessages} 
                            endPoint={"contactEmail"}
                            language={props.pageContext.language}
                        />
                    </section>
                </div>
            </Layout>
        </div>
    )
}

export default CaseStudiesTemplate

export const pageQuery = graphql`
    query GET_CASE_STUDIES($id: ID! , $language: WPGraphQL_LanguageCodeFilterEnum, $themeTemplateUri: ID! ) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                breadcrumbAcf{
                    breadcrumbName
                }
			    caseStudiesPageAcf{
                    introductionHeader
                    introductionBackgroundImage{
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 2080, quality: 80){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    mainContent
                }
            }
            caseStudies(where: {language: $language}) {
                ...CaseStudiesFragment
            }
            template(id: $themeTemplateUri, idType: SLUG) {
                contactInformationTemplate {
                    form {
                        title
                        subTitle
                        fullName
                        email{
                            label
                            errorMessage
                        }
                        subject
                        message{
                            label
                            errorMessage
                        }
                        telephone{
                            label
                            errorMessage
                        }
                        sendButton
                    }
                    responseMessages{
                        success
                        failed
                    }
                }
            }
        }
    }
`
